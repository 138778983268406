import type { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  Upload: { input: any; output: any };
};

export enum AiRecommendType {
  Ai = 'AI',
  Manual = 'MANUAL',
}

export enum AccountType {
  Admin = 'ADMIN',
  User = 'USER',
}

export type Admin = {
  __typename?: 'Admin';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
};

export type AgeRange = {
  __typename?: 'AgeRange';
  max_age?: Maybe<Scalars['Int']['output']>;
  min_age?: Maybe<Scalars['Int']['output']>;
};

export type AgeRangeInput = {
  max_age?: InputMaybe<Scalars['Int']['input']>;
  min_age?: InputMaybe<Scalars['Int']['input']>;
};

export type AgeVerificationImageInput = {
  file: Scalars['Upload']['input'];
};

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export type Area = {
  __typename?: 'Area';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['Int']['output']>;
};

export type AreaPreferenceInput = {
  area_id: Scalars['Int']['input'];
};

export type AttractionFactor = {
  __typename?: 'AttractionFactor';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pivot?: Maybe<UserAttractionFactorPivot>;
};

export type AttractionFactorInput = {
  attraction_factor_id: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

export type CancelSubscriptionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CardInput = {
  card_holder: Scalars['String']['input'];
  card_number: Scalars['String']['input'];
  cvv2: Scalars['String']['input'];
  exp_month: Scalars['String']['input'];
  exp_year: Scalars['String']['input'];
};

export type ChatRoom = {
  __typename?: 'ChatRoom';
  admins?: Maybe<Array<Admin>>;
  id: Scalars['ID']['output'];
  latestMessage?: Maybe<Message>;
  messages?: Maybe<Array<Message>>;
  unread_messages_count?: Maybe<Scalars['Int']['output']>;
  users: Array<User>;
};

export type Collaboration = {
  __typename?: 'Collaboration';
  id?: Maybe<Scalars['ID']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Media>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ContentMessage = {
  __typename?: 'ContentMessage';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ContinueAndCreateUserInput = {
  age_verification_image_media_id?: InputMaybe<Scalars['Int']['input']>;
  gender: UserGenderType;
  name: Scalars['String']['input'];
  profile_image_media_id?: InputMaybe<Scalars['Int']['input']>;
  registration_survey?: InputMaybe<RegistrationSurveyInput>;
};

export type ConvenientTime = {
  __typename?: 'ConvenientTime';
  id: Scalars['ID']['output'];
  timeframe: Scalars['String']['output'];
};

export type ConvenientTimePreferenceInput = {
  convenient_time_id: Scalars['Int']['input'];
};

export type CreateMessageInput = {
  chat_room_id: Scalars['ID']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  media_id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateRecommendationRejectionReasonInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  rejection_reason: RecommendationRejectionReasonType;
  target_user_id: Scalars['Int']['input'];
};

export type CreateRecommendationRejectionReasonPayload = {
  __typename?: 'CreateRecommendationRejectionReasonPayload';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type CreateUserBlockInput = {
  user_id: Scalars['ID']['input'];
};

export type CreditCard = {
  __typename?: 'CreditCard';
  card_number_last4: Scalars['String']['output'];
  card_number_length: Scalars['Int']['output'];
  exp_month: Scalars['String']['output'];
  exp_year: Scalars['String']['output'];
};

export type DailyRequestCounts = {
  __typename?: 'DailyRequestCounts';
  ai_count: Scalars['Int']['output'];
  manual_count: Scalars['Int']['output'];
  remaining_count: Scalars['Int']['output'];
};

export type DeleteProfileImageInput = {
  id: Scalars['ID']['input'];
};

export type DeleteReason = {
  __typename?: 'DeleteReason';
  id: Scalars['ID']['output'];
  reason: Scalars['String']['output'];
};

export type FemaleProfileHiddenList = {
  __typename?: 'FemaleProfileHiddenList';
  id: Scalars['ID']['output'];
  user?: Maybe<User>;
};

export type IsMatchedWithLoginUser = {
  __typename?: 'IsMatchedWithLoginUser';
  chat_room_id?: Maybe<Scalars['Int']['output']>;
  is_matched?: Maybe<Scalars['Boolean']['output']>;
};

export type Kink = {
  __typename?: 'Kink';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pivot?: Maybe<UserKinkPivot>;
};

export type KinkInput = {
  kink_id: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export enum KinkValue {
  AnalSex = 'ANAL_SEX',
  Bdsm = 'BDSM',
  BitingMarks = 'BITING_MARKS',
  Blowjob = 'BLOWJOB',
  Choking = 'CHOKING',
  Cosplay = 'COSPLAY',
  Cunnilingus = 'CUNNILINGUS',
  Deepthroat = 'DEEPTHROAT',
  Exhibitionism = 'EXHIBITIONISM',
  HardVerbalTease = 'HARD_VERBAL_TEASE',
  MindOrgasm = 'MIND_ORGASM',
  Orgy = 'ORGY',
  SoftVerbalTease = 'SOFT_VERBAL_TEASE',
  Spanking = 'SPANKING',
  Squirting = 'SQUIRTING',
  Toys = 'TOYS',
}

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MarkMessageAsReadInput = {
  chat_room_id: Scalars['ID']['input'];
};

export type MarkNotificationAsReadInput = {
  id: Scalars['ID']['input'];
};

export type MarkNotificationAsReadResponse = {
  __typename?: 'MarkNotificationAsReadResponse';
  link?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type MatchDetailsInput = {
  match_id: Scalars['ID']['input'];
};

export type MatchDetailsResponse = {
  __typename?: 'MatchDetailsResponse';
  chat_room_id?: Maybe<Scalars['ID']['output']>;
  login_user: User;
  target_user: User;
};

export type MatchingInput = {
  matching_user_id: Scalars['ID']['input'];
};

export type MatchingResponse = {
  __typename?: 'MatchingResponse';
  match_id?: Maybe<Scalars['ID']['output']>;
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Media = {
  __typename?: 'Media';
  deleted_at?: Maybe<Scalars['String']['output']>;
  file_path?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  pivot?: Maybe<UserMediaPivot>;
  signedUrl?: Maybe<Scalars['String']['output']>;
};

export type MediaMessage = {
  __typename?: 'MediaMessage';
  id: Scalars['ID']['output'];
  media: Media;
};

export type MediaQueryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Message = {
  __typename?: 'Message';
  chatRoom?: Maybe<ChatRoom>;
  chat_room_id: Scalars['ID']['output'];
  contains_ng_word?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_read: Scalars['Boolean']['output'];
  mediaMessage?: Maybe<MediaMessage>;
  sender?: Maybe<Sender>;
  sender_id?: Maybe<Scalars['ID']['output']>;
  sender_type?: Maybe<AccountType>;
  updated_at: Scalars['String']['output'];
};

/** A paginated list of Message edges. */
export type MessageConnection = {
  __typename?: 'MessageConnection';
  /** A list of Message edges. */
  edges: Array<MessageEdge>;
  /** Pagination information about the list of edges. */
  pageInfo: PageInfo;
};

/** An edge that contains a node of type Message and a cursor. */
export type MessageEdge = {
  __typename?: 'MessageEdge';
  /** A unique cursor that can be used for pagination. */
  cursor: Scalars['String']['output'];
  /** The Message node. */
  node: Message;
};

export type MessagesByChatRoomIdInput = {
  chat_room_id: Scalars['ID']['input'];
};

export type MessagesInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  chat_room_id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  ContinueAndCreateUser?: Maybe<User>;
  cancelSubscription?: Maybe<Scalars['Boolean']['output']>;
  createMessage: Message;
  createRecommendationRejectionReason: CreateRecommendationRejectionReasonPayload;
  createUser?: Maybe<User>;
  createUserBlock: UserBlockResponse;
  deleteProfileImage: Media;
  login: User;
  logout?: Maybe<User>;
  markMessageAsRead: Scalars['Boolean']['output'];
  markNotificationAsRead: Scalars['Boolean']['output'];
  matching: MatchingResponse;
  orderItem?: Maybe<OrderItemResponse>;
  orderSubscription?: Maybe<OrderSubscriptionResponse>;
  requestRecommend: RecommendRequestResponse;
  resendEmailVerification: MutationResponse;
  resetPassword: ResetPasswordResponse;
  sendLike: SendLikeResponse;
  showErrorSubscription?: Maybe<Scalars['Boolean']['output']>;
  terminateUser?: Maybe<User>;
  tryResetPassword: TryResetPasswordResponse;
  tryUpdateEmail: UpdateEmailResponse;
  updateAcceptanceRange?: Maybe<User>;
  updateAdditionalProfile?: Maybe<User>;
  updateCreditCard?: Maybe<CreditCard>;
  updateEmail: UpdateEmailResponse;
  updateFemaleProfileHiddenList: UpdateFemaleProfileHiddenListResponse;
  updateMood: User;
  updatePassword: UpdatePasswordResponse;
  updateProfile?: Maybe<User>;
  updateProfileImage?: Maybe<User>;
  updateProfileText?: Maybe<User>;
  uploadMedia?: Maybe<Media>;
  validateResetPasswordToken: ValidateResetPasswordTokenResponse;
  verifyEmail: VerifyEmailResponse;
};

export type MutationContinueAndCreateUserArgs = {
  input: ContinueAndCreateUserInput;
};

export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput;
};

export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};

export type MutationCreateRecommendationRejectionReasonArgs = {
  input: CreateRecommendationRejectionReasonInput;
};

export type MutationCreateUserArgs = {
  input: UserCreateInput;
};

export type MutationCreateUserBlockArgs = {
  input: CreateUserBlockInput;
};

export type MutationDeleteProfileImageArgs = {
  input: DeleteProfileImageInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationMarkMessageAsReadArgs = {
  input: MarkMessageAsReadInput;
};

export type MutationMarkNotificationAsReadArgs = {
  input: Array<MarkNotificationAsReadInput>;
};

export type MutationMatchingArgs = {
  input: MatchingInput;
};

export type MutationOrderItemArgs = {
  input: OrderItemInput;
};

export type MutationOrderSubscriptionArgs = {
  input: OrderSubscriptionInput;
};

export type MutationRequestRecommendArgs = {
  input?: InputMaybe<RequestRecommendInput>;
};

export type MutationResendEmailVerificationArgs = {
  input: ResendEmailVerificationInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationSendLikeArgs = {
  input: SendLikeInput;
};

export type MutationShowErrorSubscriptionArgs = {
  input: ShowErrorSubscriptionInput;
};

export type MutationTerminateUserArgs = {
  input: TerminateUserInput;
};

export type MutationTryResetPasswordArgs = {
  input: TryResetPasswordInput;
};

export type MutationTryUpdateEmailArgs = {
  input: TryUpdateEmailInput;
};

export type MutationUpdateAcceptanceRangeArgs = {
  input: UpdateAcceptanceRangeInput;
};

export type MutationUpdateAdditionalProfileArgs = {
  input: UpdateAdditionalProfileInput;
};

export type MutationUpdateCreditCardArgs = {
  input: CardInput;
};

export type MutationUpdateEmailArgs = {
  input: UpdateEmailInput;
};

export type MutationUpdateFemaleProfileHiddenListArgs = {
  input: UpdateFemaleProfileHiddenListInput;
};

export type MutationUpdateMoodArgs = {
  input: UpdateMoodInput;
};

export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateProfileImageArgs = {
  input: ProfileImageInput;
};

export type MutationUpdateProfileTextArgs = {
  input: UpdateProfileTextInput;
};

export type MutationUploadMediaArgs = {
  input: UploadMediaInput;
};

export type MutationValidateResetPasswordTokenArgs = {
  input: ValidateResetPasswordTokenInput;
};

export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type NewMessageSubscriptionInput = {
  chat_room_id: Scalars['ID']['input'];
};

export type News = {
  __typename?: 'News';
  categories: Array<NewsCategory>;
  content: Scalars['String']['output'];
  created_at: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: NewsStatus;
  title: Scalars['String']['output'];
};

export type NewsCategory = {
  __typename?: 'NewsCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** A paginated list of News items. */
export type NewsPaginator = {
  __typename?: 'NewsPaginator';
  /** A list of News items. */
  data: Array<News>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum NewsStatus {
  Closed = 'CLOSED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type Notification = {
  __typename?: 'Notification';
  additional_data?: Maybe<Scalars['JSON']['output']>;
  content: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  notifiable_id: Scalars['Int']['output'];
  notifiable_type: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  read_at?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String']['input'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM',
}

export type OrderItemInput = {
  card?: InputMaybe<CardInput>;
  product_id: Scalars['ID']['input'];
};

export type OrderItemResponse = {
  __typename?: 'OrderItemResponse';
  id: Scalars['ID']['output'];
};

export type OrderSubscriptionInput = {
  card?: InputMaybe<CardInput>;
  product_id: Scalars['ID']['input'];
};

export type OrderSubscriptionResponse = {
  __typename?: 'OrderSubscriptionResponse';
  id: Scalars['ID']['output'];
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']['output']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int']['output'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int']['output'];
  /** Index of the current page. */
  currentPage: Scalars['Int']['output'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']['output']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean']['output'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']['output']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int']['output'];
  /** Number of items per page. */
  perPage: Scalars['Int']['output'];
  /** Number of total available items. */
  total: Scalars['Int']['output'];
};

export type ParentAreaWithChildren = {
  __typename?: 'ParentAreaWithChildren';
  children: Array<Area>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent_id?: Maybe<Scalars['Int']['output']>;
};

export type Product = {
  __typename?: 'Product';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
};

export type Profile = {
  __typename?: 'Profile';
  age?: Maybe<Scalars['Int']['output']>;
  area?: Maybe<Area>;
  gender?: Maybe<UserGenderType>;
  genericTextboxDatas?: Maybe<Array<ProfileGenericTextboxData>>;
  id?: Maybe<Scalars['ID']['output']>;
  instagram_id?: Maybe<Scalars['String']['output']>;
  mood?: Maybe<ProfileMoodType>;
  name?: Maybe<Scalars['String']['output']>;
  profileDetails?: Maybe<Array<ProfileDetail>>;
  tiktok_id?: Maybe<Scalars['String']['output']>;
  x_id?: Maybe<Scalars['String']['output']>;
};

export type ProfileDetail = {
  __typename?: 'ProfileDetail';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pivot?: Maybe<UserProfileDetailPivot>;
};

export type ProfileDetailInput = {
  profile_detail_id: Scalars['Int']['input'];
  value: Scalars['String']['input'];
};

export type ProfileGenericTextboxData = {
  __typename?: 'ProfileGenericTextboxData';
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pivot?: Maybe<UserProfileGenericTextboxDataPivot>;
};

export type ProfileGenericTextboxDataInput = {
  content: Scalars['String']['input'];
  profile_generic_textbox_data_id: Scalars['Int']['input'];
};

export type ProfileImageInput = {
  display_order: Scalars['Int']['input'];
  media_id: Scalars['ID']['input'];
};

export type ProfileInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  area_id?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  instagram_id?: InputMaybe<Scalars['String']['input']>;
  mood?: InputMaybe<ProfileMoodType>;
  name?: InputMaybe<Scalars['String']['input']>;
  tiktok_id?: InputMaybe<Scalars['String']['input']>;
  x_id?: InputMaybe<Scalars['String']['input']>;
};

export enum ProfileMoodType {
  FeelingAroused = 'FEELING_AROUSED',
  KillingTime = 'KILLING_TIME',
  Secret = 'SECRET',
  WantCallChat = 'WANT_CALL_CHAT',
  WantHToday = 'WANT_H_TODAY',
  WantToMeet = 'WANT_TO_MEET',
}

export type Query = {
  __typename?: 'Query';
  areas?: Maybe<Array<ParentAreaWithChildren>>;
  chatRoom: ChatRoom;
  chatRooms: Array<ChatRoom>;
  collaborations?: Maybe<Array<Collaboration>>;
  dailyRequestCounts: DailyRequestCounts;
  getAttractionFactors: Array<AttractionFactor>;
  getConvenientTimes: Array<ConvenientTime>;
  getKinks: Array<Kink>;
  getNewsById?: Maybe<News>;
  getNewsList: NewsPaginator;
  getProfileDetails: Array<ProfileDetail>;
  getTerminateReasons: Array<DeleteReason>;
  getTextboxDatas: Array<ProfileGenericTextboxData>;
  match?: Maybe<UserMatch>;
  matchDetails?: Maybe<MatchDetailsResponse>;
  matches: Array<UserMatch>;
  me: User;
  media?: Maybe<Media>;
  messagesByChatRoomId: MessageConnection;
  notifications: Array<Notification>;
  paidLikeProducts: Array<Product>;
  paidSubscriptionProducts: Array<Product>;
  product: Product;
  searchUsers: SearchUsersResponse;
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
};

export type QueryChatRoomArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetNewsByIdArgs = {
  id: Scalars['ID']['input'];
};

export type QueryGetNewsListArgs = {
  first?: Scalars['Int']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMatchArgs = {
  input?: InputMaybe<UserMatchQueryInput>;
};

export type QueryMatchDetailsArgs = {
  input?: InputMaybe<MatchDetailsInput>;
};

export type QueryMediaArgs = {
  input?: InputMaybe<MediaQueryInput>;
};

export type QueryMessagesByChatRoomIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  input: MessagesByChatRoomIdInput;
};

export type QueryPaidSubscriptionProductsArgs = {
  gender?: InputMaybe<UserGenderType>;
};

export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySearchUsersArgs = {
  input: SearchUsersInput;
};

export type QueryUserArgs = {
  input?: InputMaybe<UserQueryInput>;
};

export type RecommendRequest = {
  __typename?: 'RecommendRequest';
  id: Scalars['ID']['output'];
  recommendation?: Maybe<Recommendation>;
  status: RecommendRequestStatus;
  type: AiRecommendType;
  user_id: Scalars['ID']['output'];
};

export type RecommendRequestResponse = {
  __typename?: 'RecommendRequestResponse';
  daily_request_counts: DailyRequestCounts;
  recommend_request: RecommendRequest;
};

export enum RecommendRequestStatus {
  All = 'ALL',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type Recommendation = {
  __typename?: 'Recommendation';
  id: Scalars['ID']['output'];
  maleUser: User;
  male_user_id: Scalars['ID']['output'];
  recommendRequest?: Maybe<RecommendRequest>;
  status?: Maybe<ApprovalStatus>;
};

export enum RecommendationRejectionReasonType {
  IncompatiblePreferences = 'INCOMPATIBLE_PREFERENCES',
  Other = 'OTHER',
  UnacceptableAccount = 'UNACCEPTABLE_ACCOUNT',
  UndesirablePhysique = 'UNDESIRABLE_PHYSIQUE',
  UndesirableType = 'UNDESIRABLE_TYPE',
}

export enum RegistrationStep {
  Created = 'CREATED',
  EmailVerified = 'EMAIL_VERIFIED',
  ProfileCompleted = 'PROFILE_COMPLETED',
}

export type RegistrationSurvey = {
  __typename?: 'RegistrationSurvey';
  id?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<Scalars['Int']['output']>;
  used?: Maybe<Scalars['Int']['output']>;
};

export type RegistrationSurveyInput = {
  source?: InputMaybe<Scalars['Int']['input']>;
  used?: InputMaybe<Scalars['Int']['input']>;
};

export type RequestRecommendInput = {
  type?: InputMaybe<AiRecommendType>;
};

export type ResendEmailVerificationInput = {
  token: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Role = {
  __typename?: 'Role';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SearchUsersInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
  type: UserSearchType;
};

export type SearchUsersPaginator = {
  __typename?: 'SearchUsersPaginator';
  current_page: Scalars['Int']['output'];
  data: Array<User>;
  first_item?: Maybe<Scalars['Int']['output']>;
  has_more_pages: Scalars['Boolean']['output'];
  last_item?: Maybe<Scalars['Int']['output']>;
  last_page: Scalars['Int']['output'];
  per_page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type SearchUsersResponse = {
  __typename?: 'SearchUsersResponse';
  type: Scalars['String']['output'];
  users: SearchUsersPaginator;
};

export type SendLikeInput = {
  liked_user_id: Scalars['ID']['input'];
};

export type SendLikeResponse = {
  __typename?: 'SendLikeResponse';
  available_likes_count?: Maybe<Scalars['Int']['output']>;
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type Sender = Admin | User;

export type ShowErrorSubscriptionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC',
}

export type Subscription = {
  __typename?: 'Subscription';
  newMessage?: Maybe<Message>;
};

export type SubscriptionNewMessageArgs = {
  input: NewMessageSubscriptionInput;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
}

export type TerminateUserInput = {
  delete_reason_id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT',
}

export type TryResetPasswordInput = {
  email: Scalars['String']['input'];
};

export type TryResetPasswordResponse = {
  __typename?: 'TryResetPasswordResponse';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type TryUpdateEmailInput = {
  email: Scalars['String']['input'];
};

export type UpdateAcceptanceRangeInput = {
  ageRange?: InputMaybe<AgeRangeInput>;
  convenientTimes?: InputMaybe<Array<ConvenientTimePreferenceInput>>;
  profileDetails?: InputMaybe<Array<ProfileDetailInput>>;
};

export type UpdateAdditionalProfileInput = {
  attractionFactors?: InputMaybe<Array<AttractionFactorInput>>;
  kinks?: InputMaybe<Array<KinkInput>>;
  profileDetails?: InputMaybe<Array<ProfileDetailInput>>;
};

export type UpdateEmailInput = {
  token: Scalars['String']['input'];
};

export type UpdateEmailResponse = {
  __typename?: 'UpdateEmailResponse';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type UpdateFemaleProfileHiddenListInput = {
  is_hidden: Scalars['Boolean']['input'];
};

export type UpdateFemaleProfileHiddenListResponse = {
  __typename?: 'UpdateFemaleProfileHiddenListResponse';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type UpdateMoodInput = {
  mood: ProfileMoodType;
};

export type UpdatePasswordInput = {
  confirmPassword: Scalars['String']['input'];
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type UpdateProfileInput = {
  areaPreferences: Array<AreaPreferenceInput>;
  profile: ProfileInput;
  profileDetails: Array<ProfileDetailInput>;
};

export type UpdateProfileTextInput = {
  genericTextboxDatas?: InputMaybe<Array<ProfileGenericTextboxDataInput>>;
};

export type UploadMediaInput = {
  file: Scalars['Upload']['input'];
};

export type User = {
  __typename?: 'User';
  activeCreditCard?: Maybe<CreditCard>;
  activeUserSubscription?: Maybe<UserSubscription>;
  adminChatRoomId?: Maybe<Scalars['Int']['output']>;
  ageRange?: Maybe<AgeRange>;
  ageVerificationImages?: Maybe<Array<Media>>;
  areaPreferences?: Maybe<Array<Area>>;
  attractionFactors?: Maybe<Array<AttractionFactor>>;
  availableLikesCount?: Maybe<Scalars['Int']['output']>;
  chatRooms?: Maybe<Array<ChatRoom>>;
  convenientTimes?: Maybe<Array<ConvenientTime>>;
  created_at?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  femaleProfileHiddenList?: Maybe<FemaleProfileHiddenList>;
  hasLikedLoginUser?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isActiveFemalePremium?: Maybe<Scalars['Boolean']['output']>;
  isLikedByLoginUser?: Maybe<Scalars['Boolean']['output']>;
  isMatchedWithLoginUser?: Maybe<IsMatchedWithLoginUser>;
  is_active?: Maybe<Scalars['Boolean']['output']>;
  kinks?: Maybe<Array<Kink>>;
  latestUserSubscription?: Maybe<UserSubscription>;
  matchCount?: Maybe<Scalars['Int']['output']>;
  matches?: Maybe<Array<UserMatch>>;
  messages?: Maybe<Array<Message>>;
  password?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Profile>;
  profileImages?: Maybe<Array<Media>>;
  receivedLikesCount?: Maybe<Scalars['Int']['output']>;
  recommendStatusForLoginUser?: Maybe<ApprovalStatus>;
  recommendations?: Maybe<Array<Recommendation>>;
  recommendationsCount?: Maybe<Scalars['Int']['output']>;
  registrationSurvey?: Maybe<RegistrationSurvey>;
  registration_step?: Maybe<RegistrationStep>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type UserAttractionFactorPivot = {
  __typename?: 'UserAttractionFactorPivot';
  description?: Maybe<Scalars['String']['output']>;
};

export type UserBlockResponse = {
  __typename?: 'UserBlockResponse';
  blocked_user: User;
  created_at: Scalars['String']['output'];
  updated_at: Scalars['String']['output'];
};

export type UserCreateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export enum UserGenderType {
  Female = 'FEMALE',
  Male = 'MALE',
}

export type UserKinkPivot = {
  __typename?: 'UserKinkPivot';
  value?: Maybe<Scalars['String']['output']>;
};

export type UserMatch = {
  __typename?: 'UserMatch';
  created_at?: Maybe<Scalars['String']['output']>;
  female_user_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  male_user_id?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Array<User>>;
};

export type UserMatchQueryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UserMediaPivot = {
  __typename?: 'UserMediaPivot';
  display_order?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ApprovalStatus>;
  verification_status?: Maybe<ApprovalStatus>;
};

export type UserProfileDetailPivot = {
  __typename?: 'UserProfileDetailPivot';
  value?: Maybe<Scalars['String']['output']>;
};

export type UserProfileGenericTextboxDataPivot = {
  __typename?: 'UserProfileGenericTextboxDataPivot';
  content: Scalars['String']['output'];
  status: ApprovalStatus;
};

export type UserQueryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum UserSearchType {
  Compatible = 'COMPATIBLE',
  HighlyResponsive = 'HIGHLY_RESPONSIVE',
  Liked = 'LIKED',
  LikedBy = 'LIKED_BY',
  Matched = 'MATCHED',
  Nearby = 'NEARBY',
  Nomatched = 'NOMATCHED',
  Popular = 'POPULAR',
  ProfileVisitors = 'PROFILE_VISITORS',
  RecentlyJoined = 'RECENTLY_JOINED',
  Recommended = 'RECOMMENDED',
  VisitedProfiles = 'VISITED_PROFILES',
}

export type UserSubscription = {
  __typename?: 'UserSubscription';
  created_at: Scalars['String']['output'];
  display_expired_at: Scalars['String']['output'];
  display_joined_at: Scalars['String']['output'];
  end_date: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  is_shown_error?: Maybe<Scalars['Boolean']['output']>;
  product: Product;
  status: SubscriptionStatus;
};

export type ValidateResetPasswordToken = {
  __typename?: 'ValidateResetPasswordToken';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ValidateResetPasswordTokenInput = {
  token: Scalars['String']['input'];
};

export type ValidateResetPasswordTokenResponse = {
  __typename?: 'ValidateResetPasswordTokenResponse';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type VerifyEmailInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  token: Scalars['String']['input'];
};

export type VerifyEmailResponse = {
  __typename?: 'VerifyEmailResponse';
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type CancelSubscriptionMutationVariables = Exact<{
  input: CancelSubscriptionInput;
}>;

export type CancelSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription?: boolean | null;
};

export type ContinueAndCreateUserMutationVariables = Exact<{
  input: ContinueAndCreateUserInput;
}>;

export type ContinueAndCreateUserMutation = {
  __typename?: 'Mutation';
  ContinueAndCreateUser?: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    password?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  } | null;
};

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;

export type CreateMessageMutation = {
  __typename?: 'Mutation';
  createMessage: {
    __typename?: 'Message';
    id: string;
    content?: string | null;
    sender?:
      | { __typename?: 'Admin'; id: string }
      | { __typename?: 'User'; id: string }
      | null;
    mediaMessage?: {
      __typename?: 'MediaMessage';
      id: string;
      media: {
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
      };
    } | null;
  };
};

export type CreateRecommendationRejectionReasonMutationVariables = Exact<{
  input: CreateRecommendationRejectionReasonInput;
}>;

export type CreateRecommendationRejectionReasonMutation = {
  __typename?: 'Mutation';
  createRecommendationRejectionReason: {
    __typename?: 'CreateRecommendationRejectionReasonPayload';
    status: string;
    message: string;
  };
};

export type CreateUserMutationVariables = Exact<{
  input: UserCreateInput;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'User';
    id: string;
    email?: string | null;
  } | null;
};

export type DeleteProfileImageMutationVariables = Exact<{
  input: DeleteProfileImageInput;
}>;

export type DeleteProfileImageMutation = {
  __typename?: 'Mutation';
  deleteProfileImage: {
    __typename?: 'Media';
    id?: string | null;
    deleted_at?: string | null;
  };
};

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    created_at?: string | null;
    updated_at?: string | null;
    profile?: {
      __typename?: 'Profile';
      id?: string | null;
      gender?: UserGenderType | null;
    } | null;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout?: {
    __typename?: 'User';
    id: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  } | null;
};

export type MarkMessageAsReadMutationVariables = Exact<{
  input: MarkMessageAsReadInput;
}>;

export type MarkMessageAsReadMutation = {
  __typename?: 'Mutation';
  markMessageAsRead: boolean;
};

export type MarkNotificationAsReadMutationVariables = Exact<{
  input: Array<MarkNotificationAsReadInput> | MarkNotificationAsReadInput;
}>;

export type MarkNotificationAsReadMutation = {
  __typename?: 'Mutation';
  markNotificationAsRead: boolean;
};

export type MatchingMutationVariables = Exact<{
  input: MatchingInput;
}>;

export type MatchingMutation = {
  __typename?: 'Mutation';
  matching: {
    __typename?: 'MatchingResponse';
    status: string;
    message: string;
    match_id?: string | null;
  };
};

export type OrderItemMutationVariables = Exact<{
  input: OrderItemInput;
}>;

export type OrderItemMutation = {
  __typename?: 'Mutation';
  orderItem?: { __typename?: 'OrderItemResponse'; id: string } | null;
};

export type OrderSubscriptionMutationVariables = Exact<{
  input: OrderSubscriptionInput;
}>;

export type OrderSubscriptionMutation = {
  __typename?: 'Mutation';
  orderSubscription?: {
    __typename?: 'OrderSubscriptionResponse';
    id: string;
  } | null;
};

export type RequestRecommendMutationVariables = Exact<{
  input: RequestRecommendInput;
}>;

export type RequestRecommendMutation = {
  __typename?: 'Mutation';
  requestRecommend: {
    __typename?: 'RecommendRequestResponse';
    recommend_request: {
      __typename?: 'RecommendRequest';
      id: string;
      user_id: string;
      type: AiRecommendType;
      status: RecommendRequestStatus;
      recommendation?: {
        __typename?: 'Recommendation';
        id: string;
        male_user_id: string;
        status?: ApprovalStatus | null;
        maleUser: {
          __typename?: 'User';
          id: string;
          email?: string | null;
          created_at?: string | null;
        };
      } | null;
    };
    daily_request_counts: {
      __typename?: 'DailyRequestCounts';
      remaining_count: number;
      manual_count: number;
      ai_count: number;
    };
  };
};

export type ResendEmailVerificationMutationVariables = Exact<{
  input: ResendEmailVerificationInput;
}>;

export type ResendEmailVerificationMutation = {
  __typename?: 'Mutation';
  resendEmailVerification: {
    __typename?: 'MutationResponse';
    status: string;
    message: string;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: {
    __typename?: 'ResetPasswordResponse';
    status: string;
    message: string;
  };
};

export type SendLikeMutationVariables = Exact<{
  input: SendLikeInput;
}>;

export type SendLikeMutation = {
  __typename?: 'Mutation';
  sendLike: {
    __typename?: 'SendLikeResponse';
    status: string;
    message: string;
    available_likes_count?: number | null;
  };
};

export type ShowErrorSubscriptionMutationVariables = Exact<{
  input: ShowErrorSubscriptionInput;
}>;

export type ShowErrorSubscriptionMutation = {
  __typename?: 'Mutation';
  showErrorSubscription?: boolean | null;
};

export type TerminateUserMutationVariables = Exact<{
  input: TerminateUserInput;
}>;

export type TerminateUserMutation = {
  __typename?: 'Mutation';
  terminateUser?: {
    __typename?: 'User';
    id: string;
    is_active?: boolean | null;
    deleted_at?: string | null;
  } | null;
};

export type TryResetPasswordMutationVariables = Exact<{
  input: TryResetPasswordInput;
}>;

export type TryResetPasswordMutation = {
  __typename?: 'Mutation';
  tryResetPassword: {
    __typename?: 'TryResetPasswordResponse';
    status: string;
    message: string;
  };
};

export type TryUpdateEmailMutationVariables = Exact<{
  input: TryUpdateEmailInput;
}>;

export type TryUpdateEmailMutation = {
  __typename?: 'Mutation';
  tryUpdateEmail: {
    __typename?: 'UpdateEmailResponse';
    status: string;
    message: string;
  };
};

export type UpdateAcceptanceRangeMutationVariables = Exact<{
  input: UpdateAcceptanceRangeInput;
}>;

export type UpdateAcceptanceRangeMutation = {
  __typename?: 'Mutation';
  updateAcceptanceRange?: {
    __typename?: 'User';
    id: string;
    profile?: {
      __typename?: 'Profile';
      profileDetails?: Array<{
        __typename?: 'ProfileDetail';
        id: string;
        name: string;
        pivot?: {
          __typename?: 'UserProfileDetailPivot';
          value?: string | null;
        } | null;
      }> | null;
    } | null;
    ageRange?: {
      __typename?: 'AgeRange';
      min_age?: number | null;
      max_age?: number | null;
    } | null;
    convenientTimes?: Array<{
      __typename?: 'ConvenientTime';
      id: string;
      timeframe: string;
    }> | null;
  } | null;
};

export type UpdateAdditionalProfileMutationVariables = Exact<{
  input: UpdateAdditionalProfileInput;
}>;

export type UpdateAdditionalProfileMutation = {
  __typename?: 'Mutation';
  updateAdditionalProfile?: {
    __typename?: 'User';
    id: string;
    profile?: {
      __typename?: 'Profile';
      profileDetails?: Array<{
        __typename?: 'ProfileDetail';
        id: string;
        name: string;
        pivot?: {
          __typename?: 'UserProfileDetailPivot';
          value?: string | null;
        } | null;
      }> | null;
    } | null;
    attractionFactors?: Array<{
      __typename?: 'AttractionFactor';
      id: string;
      content: string;
      pivot?: {
        __typename?: 'UserAttractionFactorPivot';
        description?: string | null;
      } | null;
    }> | null;
    kinks?: Array<{
      __typename?: 'Kink';
      id: string;
      name: string;
      pivot?: { __typename?: 'UserKinkPivot'; value?: string | null } | null;
    }> | null;
  } | null;
};

export type UpdateCreditCardMutationVariables = Exact<{
  input: CardInput;
}>;

export type UpdateCreditCardMutation = {
  __typename?: 'Mutation';
  updateCreditCard?: {
    __typename?: 'CreditCard';
    card_number_last4: string;
    card_number_length: number;
    exp_month: string;
    exp_year: string;
  } | null;
};

export type UpdateEmailMutationVariables = Exact<{
  input: UpdateEmailInput;
}>;

export type UpdateEmailMutation = {
  __typename?: 'Mutation';
  updateEmail: {
    __typename?: 'UpdateEmailResponse';
    status: string;
    message: string;
  };
};

export type UpdateFemaleProfileHiddenListMutationVariables = Exact<{
  input: UpdateFemaleProfileHiddenListInput;
}>;

export type UpdateFemaleProfileHiddenListMutation = {
  __typename?: 'Mutation';
  updateFemaleProfileHiddenList: {
    __typename?: 'UpdateFemaleProfileHiddenListResponse';
    status: string;
    message: string;
  };
};

export type UpdateMoodMutationVariables = Exact<{
  input: UpdateMoodInput;
}>;

export type UpdateMoodMutation = {
  __typename?: 'Mutation';
  updateMood: {
    __typename?: 'User';
    id: string;
    profile?: {
      __typename?: 'Profile';
      id?: string | null;
      mood?: ProfileMoodType | null;
    } | null;
  };
};

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;

export type UpdatePasswordMutation = {
  __typename?: 'Mutation';
  updatePassword: {
    __typename?: 'UpdatePasswordResponse';
    status: string;
    message: string;
  };
};

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile?: {
    __typename?: 'User';
    id: string;
    profile?: {
      __typename?: 'Profile';
      name?: string | null;
      age?: number | null;
      x_id?: string | null;
      tiktok_id?: string | null;
      instagram_id?: string | null;
      area?: { __typename?: 'Area'; name: string } | null;
      profileDetails?: Array<{
        __typename?: 'ProfileDetail';
        id: string;
        name: string;
        pivot?: {
          __typename?: 'UserProfileDetailPivot';
          value?: string | null;
        } | null;
      }> | null;
    } | null;
    areaPreferences?: Array<{ __typename?: 'Area'; name: string }> | null;
  } | null;
};

export type UpdateProfileImageMutationVariables = Exact<{
  input: ProfileImageInput;
}>;

export type UpdateProfileImageMutation = {
  __typename?: 'Mutation';
  updateProfileImage?: {
    __typename?: 'User';
    id: string;
    profileImages?: Array<{
      __typename?: 'Media';
      id?: string | null;
      file_path?: string | null;
      signedUrl?: string | null;
      pivot?: {
        __typename?: 'UserMediaPivot';
        display_order?: number | null;
        verification_status?: ApprovalStatus | null;
      } | null;
    }> | null;
  } | null;
};

export type UpdateProfileTextMutationVariables = Exact<{
  input: UpdateProfileTextInput;
}>;

export type UpdateProfileTextMutation = {
  __typename?: 'Mutation';
  updateProfileText?: {
    __typename?: 'User';
    id: string;
    profile?: {
      __typename?: 'Profile';
      genericTextboxDatas?: Array<{
        __typename?: 'ProfileGenericTextboxData';
        id: string;
        name: string;
        pivot?: {
          __typename?: 'UserProfileGenericTextboxDataPivot';
          content: string;
          status: ApprovalStatus;
        } | null;
      }> | null;
    } | null;
  } | null;
};

export type UploadMediaMutationVariables = Exact<{
  input: UploadMediaInput;
}>;

export type UploadMediaMutation = {
  __typename?: 'Mutation';
  uploadMedia?: {
    __typename?: 'Media';
    id?: string | null;
    file_path?: string | null;
    deleted_at?: string | null;
    signedUrl?: string | null;
  } | null;
};

export type ValidateResetPasswordTokenMutationVariables = Exact<{
  input: ValidateResetPasswordTokenInput;
}>;

export type ValidateResetPasswordTokenMutation = {
  __typename?: 'Mutation';
  validateResetPasswordToken: {
    __typename?: 'ValidateResetPasswordTokenResponse';
    status: string;
    message: string;
  };
};

export type VerifyEmailMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;

export type VerifyEmailMutation = {
  __typename?: 'Mutation';
  verifyEmail: {
    __typename?: 'VerifyEmailResponse';
    status: string;
    message: string;
  };
};

export type AreasQueryVariables = Exact<{ [key: string]: never }>;

export type AreasQuery = {
  __typename?: 'Query';
  areas?: Array<{
    __typename?: 'ParentAreaWithChildren';
    id: string;
    name: string;
    children: Array<{
      __typename?: 'Area';
      id: string;
      name: string;
      parent_id?: number | null;
    }>;
  }> | null;
};

export type ChatPageDataQueryVariables = Exact<{ [key: string]: never }>;

export type ChatPageDataQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    created_at?: string | null;
    is_active?: boolean | null;
    isActiveFemalePremium?: boolean | null;
    availableLikesCount?: number | null;
    recommendationsCount?: number | null;
    matchCount?: number | null;
    receivedLikesCount?: number | null;
    adminChatRoomId?: number | null;
    profile?: {
      __typename?: 'Profile';
      id?: string | null;
      name?: string | null;
      gender?: UserGenderType | null;
      age?: number | null;
      x_id?: string | null;
      tiktok_id?: string | null;
      instagram_id?: string | null;
    } | null;
    profileImages?: Array<{
      __typename?: 'Media';
      id?: string | null;
      file_path?: string | null;
      signedUrl?: string | null;
      pivot?: {
        __typename?: 'UserMediaPivot';
        display_order?: number | null;
        verification_status?: ApprovalStatus | null;
      } | null;
    }> | null;
    activeCreditCard?: {
      __typename?: 'CreditCard';
      card_number_last4: string;
      card_number_length: number;
      exp_month: string;
      exp_year: string;
    } | null;
    latestUserSubscription?: {
      __typename?: 'UserSubscription';
      id: string;
      display_joined_at: string;
      display_expired_at: string;
      status: SubscriptionStatus;
      is_shown_error?: boolean | null;
      product: {
        __typename?: 'Product';
        name: string;
        id: number;
        description: string;
        price: number;
      };
    } | null;
    activeUserSubscription?: {
      __typename?: 'UserSubscription';
      id: string;
      created_at: string;
      end_date: string;
      display_joined_at: string;
      display_expired_at: string;
      product: {
        __typename?: 'Product';
        name: string;
        id: number;
        description: string;
        price: number;
      };
    } | null;
  };
  chatRooms: Array<{
    __typename?: 'ChatRoom';
    id: string;
    unread_messages_count?: number | null;
    users: Array<{
      __typename?: 'User';
      id: string;
      email?: string | null;
      created_at?: string | null;
      is_active?: boolean | null;
      profile?: { __typename?: 'Profile'; name?: string | null } | null;
      profileImages?: Array<{
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
        pivot?: {
          __typename?: 'UserMediaPivot';
          display_order?: number | null;
          verification_status?: ApprovalStatus | null;
        } | null;
      }> | null;
    }>;
    admins?: Array<{ __typename?: 'Admin'; id: string }> | null;
    latestMessage?: {
      __typename?: 'Message';
      id: string;
      sender_id?: string | null;
      sender_type?: AccountType | null;
      chat_room_id: string;
      content?: string | null;
      is_read: boolean;
      created_at: string;
      updated_at: string;
      mediaMessage?: {
        __typename?: 'MediaMessage';
        id: string;
        media: {
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
        };
      } | null;
    } | null;
  }>;
  matches: Array<{
    __typename?: 'UserMatch';
    id?: string | null;
    created_at?: string | null;
    users?: Array<{
      __typename?: 'User';
      id: string;
      email?: string | null;
      profile?: { __typename?: 'Profile'; name?: string | null } | null;
      profileImages?: Array<{
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
        pivot?: {
          __typename?: 'UserMediaPivot';
          display_order?: number | null;
          verification_status?: ApprovalStatus | null;
        } | null;
      }> | null;
    }> | null;
  }>;
};

export type ChatRoomQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ChatRoomQuery = {
  __typename?: 'Query';
  chatRoom: {
    __typename?: 'ChatRoom';
    id: string;
    users: Array<{
      __typename?: 'User';
      id: string;
      email?: string | null;
      is_active?: boolean | null;
      created_at?: string | null;
      profile?: { __typename?: 'Profile'; name?: string | null } | null;
      profileImages?: Array<{
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
        pivot?: {
          __typename?: 'UserMediaPivot';
          display_order?: number | null;
          verification_status?: ApprovalStatus | null;
        } | null;
      }> | null;
    }>;
    admins?: Array<{ __typename?: 'Admin'; id: string }> | null;
  };
};

export type ChatRoomDataQueryVariables = Exact<{
  roomId: Scalars['ID']['input'];
  messageLimit: Scalars['Int']['input'];
  messageCursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type ChatRoomDataQuery = {
  __typename?: 'Query';
  chatRoom: {
    __typename?: 'ChatRoom';
    id: string;
    users: Array<{
      __typename?: 'User';
      id: string;
      email?: string | null;
      is_active?: boolean | null;
      created_at?: string | null;
      profile?: { __typename?: 'Profile'; name?: string | null } | null;
      profileImages?: Array<{
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
        pivot?: {
          __typename?: 'UserMediaPivot';
          display_order?: number | null;
          verification_status?: ApprovalStatus | null;
        } | null;
      }> | null;
    }>;
    admins?: Array<{ __typename?: 'Admin'; id: string }> | null;
  };
  messagesByChatRoomId: {
    __typename?: 'MessageConnection';
    edges: Array<{
      __typename?: 'MessageEdge';
      cursor: string;
      node: {
        __typename?: 'Message';
        id: string;
        sender_type?: AccountType | null;
        content?: string | null;
        created_at: string;
        sender?:
          | { __typename?: 'Admin'; id: string }
          | {
              __typename?: 'User';
              id: string;
              email?: string | null;
              created_at?: string | null;
              profile?: {
                __typename?: 'Profile';
                id?: string | null;
                name?: string | null;
                gender?: UserGenderType | null;
                age?: number | null;
                x_id?: string | null;
                tiktok_id?: string | null;
                instagram_id?: string | null;
              } | null;
              profileImages?: Array<{
                __typename?: 'Media';
                id?: string | null;
                file_path?: string | null;
                signedUrl?: string | null;
                pivot?: {
                  __typename?: 'UserMediaPivot';
                  display_order?: number | null;
                  verification_status?: ApprovalStatus | null;
                } | null;
              }> | null;
            }
          | null;
        mediaMessage?: {
          __typename?: 'MediaMessage';
          id: string;
          media: {
            __typename?: 'Media';
            id?: string | null;
            file_path?: string | null;
            signedUrl?: string | null;
          };
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
    };
  };
  me: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    is_active?: boolean | null;
  };
};

export type ChatRoomsQueryVariables = Exact<{ [key: string]: never }>;

export type ChatRoomsQuery = {
  __typename?: 'Query';
  chatRooms: Array<{
    __typename?: 'ChatRoom';
    id: string;
    unread_messages_count?: number | null;
    users: Array<{
      __typename?: 'User';
      id: string;
      email?: string | null;
      created_at?: string | null;
      is_active?: boolean | null;
      profile?: { __typename?: 'Profile'; name?: string | null } | null;
      profileImages?: Array<{
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
        pivot?: {
          __typename?: 'UserMediaPivot';
          display_order?: number | null;
          verification_status?: ApprovalStatus | null;
        } | null;
      }> | null;
    }>;
    admins?: Array<{ __typename?: 'Admin'; id: string }> | null;
    latestMessage?: {
      __typename?: 'Message';
      id: string;
      sender_id?: string | null;
      sender_type?: AccountType | null;
      chat_room_id: string;
      content?: string | null;
      is_read: boolean;
      created_at: string;
      updated_at: string;
      mediaMessage?: {
        __typename?: 'MediaMessage';
        id: string;
        media: {
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
        };
      } | null;
    } | null;
  }>;
};

export type CollaborationsQueryVariables = Exact<{ [key: string]: never }>;

export type CollaborationsQuery = {
  __typename?: 'Query';
  collaborations?: Array<{
    __typename?: 'Collaboration';
    id?: string | null;
    link?: string | null;
    name?: string | null;
    media?: {
      __typename?: 'Media';
      id?: string | null;
      file_path?: string | null;
      signedUrl?: string | null;
    } | null;
  }> | null;
};

export type DailyRequestCountsQueryVariables = Exact<{ [key: string]: never }>;

export type DailyRequestCountsQuery = {
  __typename?: 'Query';
  dailyRequestCounts: {
    __typename?: 'DailyRequestCounts';
    remaining_count: number;
    manual_count: number;
    ai_count: number;
  };
};

export type GetAttractionFactorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAttractionFactorsQuery = {
  __typename?: 'Query';
  getAttractionFactors: Array<{
    __typename?: 'AttractionFactor';
    id: string;
    content: string;
  }>;
};

export type GetConvenientTimesQueryVariables = Exact<{ [key: string]: never }>;

export type GetConvenientTimesQuery = {
  __typename?: 'Query';
  getConvenientTimes: Array<{
    __typename?: 'ConvenientTime';
    id: string;
    timeframe: string;
  }>;
};

export type GetFemaleTopDataQueryVariables = Exact<{
  compatibleInput: SearchUsersInput;
  recentlyJoinedInput: SearchUsersInput;
  highlyResponsiveInput: SearchUsersInput;
  nearbyInput: SearchUsersInput;
}>;

export type GetFemaleTopDataQuery = {
  __typename?: 'Query';
  compatible: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
  recentlyJoined: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
  highlyResponsive: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
  nearby: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
};

export type GetKinksQueryVariables = Exact<{ [key: string]: never }>;

export type GetKinksQuery = {
  __typename?: 'Query';
  getKinks: Array<{ __typename?: 'Kink'; id: string; name: string }>;
};

export type GetMaleTopDataQueryVariables = Exact<{
  compatibleInput: SearchUsersInput;
  recentlyJoinedInput: SearchUsersInput;
  popularInput: SearchUsersInput;
  nearbyInput: SearchUsersInput;
}>;

export type GetMaleTopDataQuery = {
  __typename?: 'Query';
  compatible: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
  recentlyJoined: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
  popular: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
  nearby: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
};

export type GetNewsByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetNewsByIdQuery = {
  __typename?: 'Query';
  getNewsById?: {
    __typename?: 'News';
    id: string;
    title: string;
    content: string;
    status: NewsStatus;
    created_at: string;
    categories: Array<{
      __typename?: 'NewsCategory';
      id: string;
      name: string;
    }>;
  } | null;
};

export type GetNewsListQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
}>;

export type GetNewsListQuery = {
  __typename?: 'Query';
  getNewsList: {
    __typename?: 'NewsPaginator';
    paginatorInfo: {
      __typename?: 'PaginatorInfo';
      count: number;
      currentPage: number;
      firstItem?: number | null;
      hasMorePages: boolean;
      lastItem?: number | null;
      lastPage: number;
      perPage: number;
      total: number;
    };
    data: Array<{
      __typename?: 'News';
      id: string;
      title: string;
      content: string;
      status: NewsStatus;
      created_at: string;
      categories: Array<{
        __typename?: 'NewsCategory';
        id: string;
        name: string;
      }>;
    }>;
  };
};

export type GetProfileDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileDetailsQuery = {
  __typename?: 'Query';
  getProfileDetails: Array<{
    __typename?: 'ProfileDetail';
    id: string;
    name: string;
    key: string;
  }>;
};

export type GetProfileMasterQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileMasterQuery = {
  __typename?: 'Query';
  areas?: Array<{
    __typename?: 'ParentAreaWithChildren';
    id: string;
    name: string;
    children: Array<{
      __typename?: 'Area';
      id: string;
      name: string;
      parent_id?: number | null;
    }>;
  }> | null;
  getConvenientTimes: Array<{
    __typename?: 'ConvenientTime';
    id: string;
    timeframe: string;
  }>;
  getKinks: Array<{ __typename?: 'Kink'; id: string; name: string }>;
  getProfileDetails: Array<{
    __typename?: 'ProfileDetail';
    id: string;
    name: string;
    key: string;
  }>;
  getTextboxDatas: Array<{
    __typename?: 'ProfileGenericTextboxData';
    id: string;
    name: string;
    key: string;
  }>;
};

export type GetTerminateReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTerminateReasonsQuery = {
  __typename?: 'Query';
  getTerminateReasons: Array<{
    __typename?: 'DeleteReason';
    id: string;
    reason: string;
  }>;
};

export type GetTextboxDatasQueryVariables = Exact<{ [key: string]: never }>;

export type GetTextboxDatasQuery = {
  __typename?: 'Query';
  getTextboxDatas: Array<{
    __typename?: 'ProfileGenericTextboxData';
    id: string;
    name: string;
    key: string;
  }>;
};

export type LoginCheckMeQueryVariables = Exact<{ [key: string]: never }>;

export type LoginCheckMeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    is_active?: boolean | null;
    registration_step?: RegistrationStep | null;
    adminChatRoomId?: number | null;
    profile?: {
      __typename?: 'Profile';
      id?: string | null;
      name?: string | null;
      gender?: UserGenderType | null;
    } | null;
    ageVerificationImages?: Array<{
      __typename?: 'Media';
      id?: string | null;
      file_path?: string | null;
      signedUrl?: string | null;
      pivot?: {
        __typename?: 'UserMediaPivot';
        status?: ApprovalStatus | null;
      } | null;
    }> | null;
    latestUserSubscription?: {
      __typename?: 'UserSubscription';
      id: string;
      status: SubscriptionStatus;
      is_shown_error?: boolean | null;
    } | null;
  };
};

export type MatchDetailsQueryVariables = Exact<{
  input: MatchDetailsInput;
}>;

export type MatchDetailsQuery = {
  __typename?: 'Query';
  matchDetails?: {
    __typename?: 'MatchDetailsResponse';
    chat_room_id?: string | null;
    login_user: {
      __typename?: 'User';
      id: string;
      profile?: {
        __typename?: 'Profile';
        name?: string | null;
        gender?: UserGenderType | null;
      } | null;
      profileImages?: Array<{
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
        pivot?: {
          __typename?: 'UserMediaPivot';
          display_order?: number | null;
          verification_status?: ApprovalStatus | null;
        } | null;
      }> | null;
    };
    target_user: {
      __typename?: 'User';
      id: string;
      profile?: {
        __typename?: 'Profile';
        name?: string | null;
        gender?: UserGenderType | null;
      } | null;
      profileImages?: Array<{
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
        pivot?: {
          __typename?: 'UserMediaPivot';
          display_order?: number | null;
          verification_status?: ApprovalStatus | null;
        } | null;
      }> | null;
    };
  } | null;
};

export type MatchesQueryVariables = Exact<{ [key: string]: never }>;

export type MatchesQuery = {
  __typename?: 'Query';
  matches: Array<{
    __typename?: 'UserMatch';
    id?: string | null;
    created_at?: string | null;
    users?: Array<{
      __typename?: 'User';
      id: string;
      email?: string | null;
      created_at?: string | null;
      profile?: { __typename?: 'Profile'; name?: string | null } | null;
      profileImages?: Array<{
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
        pivot?: {
          __typename?: 'UserMediaPivot';
          display_order?: number | null;
          verification_status?: ApprovalStatus | null;
        } | null;
      }> | null;
    }> | null;
  }>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    created_at?: string | null;
    is_active?: boolean | null;
    isActiveFemalePremium?: boolean | null;
    availableLikesCount?: number | null;
    recommendationsCount?: number | null;
    matchCount?: number | null;
    receivedLikesCount?: number | null;
    adminChatRoomId?: number | null;
    profile?: {
      __typename?: 'Profile';
      id?: string | null;
      name?: string | null;
      gender?: UserGenderType | null;
      age?: number | null;
      x_id?: string | null;
      tiktok_id?: string | null;
      instagram_id?: string | null;
      area?: {
        __typename?: 'Area';
        id: string;
        parent_id?: number | null;
        name: string;
      } | null;
      profileDetails?: Array<{
        __typename?: 'ProfileDetail';
        id: string;
        name: string;
        key: string;
        pivot?: {
          __typename?: 'UserProfileDetailPivot';
          value?: string | null;
        } | null;
      }> | null;
      genericTextboxDatas?: Array<{
        __typename?: 'ProfileGenericTextboxData';
        id: string;
        name: string;
        key: string;
        pivot?: {
          __typename?: 'UserProfileGenericTextboxDataPivot';
          content: string;
          status: ApprovalStatus;
        } | null;
      }> | null;
    } | null;
    profileImages?: Array<{
      __typename?: 'Media';
      id?: string | null;
      file_path?: string | null;
      signedUrl?: string | null;
      pivot?: {
        __typename?: 'UserMediaPivot';
        display_order?: number | null;
        verification_status?: ApprovalStatus | null;
      } | null;
    }> | null;
    femaleProfileHiddenList?: {
      __typename?: 'FemaleProfileHiddenList';
      id: string;
    } | null;
    kinks?: Array<{
      __typename?: 'Kink';
      id: string;
      name: string;
      description?: string | null;
      pivot?: { __typename?: 'UserKinkPivot'; value?: string | null } | null;
    }> | null;
    convenientTimes?: Array<{
      __typename?: 'ConvenientTime';
      id: string;
      timeframe: string;
    }> | null;
    areaPreferences?: Array<{
      __typename?: 'Area';
      id: string;
      parent_id?: number | null;
      name: string;
    }> | null;
    attractionFactors?: Array<{
      __typename?: 'AttractionFactor';
      id: string;
      content: string;
      pivot?: {
        __typename?: 'UserAttractionFactorPivot';
        description?: string | null;
      } | null;
    }> | null;
    ageRange?: {
      __typename?: 'AgeRange';
      min_age?: number | null;
      max_age?: number | null;
    } | null;
    activeCreditCard?: {
      __typename?: 'CreditCard';
      card_number_last4: string;
      card_number_length: number;
      exp_month: string;
      exp_year: string;
    } | null;
    activeUserSubscription?: {
      __typename?: 'UserSubscription';
      id: string;
      display_joined_at: string;
      display_expired_at: string;
      product: {
        __typename?: 'Product';
        name: string;
        id: number;
        description: string;
        price: number;
      };
    } | null;
    latestUserSubscription?: {
      __typename?: 'UserSubscription';
      id: string;
      display_joined_at: string;
      display_expired_at: string;
      status: SubscriptionStatus;
      is_shown_error?: boolean | null;
      product: {
        __typename?: 'Product';
        name: string;
        id: number;
        description: string;
        price: number;
      };
    } | null;
  };
};

export type MessagesByChatRoomIdQueryVariables = Exact<{
  input: MessagesByChatRoomIdInput;
  limit: Scalars['Int']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;

export type MessagesByChatRoomIdQuery = {
  __typename?: 'Query';
  messagesByChatRoomId: {
    __typename?: 'MessageConnection';
    edges: Array<{
      __typename?: 'MessageEdge';
      cursor: string;
      node: {
        __typename?: 'Message';
        id: string;
        sender_type?: AccountType | null;
        content?: string | null;
        created_at: string;
        sender?:
          | { __typename?: 'Admin'; id: string }
          | {
              __typename?: 'User';
              id: string;
              email?: string | null;
              created_at?: string | null;
              profile?: {
                __typename?: 'Profile';
                id?: string | null;
                name?: string | null;
                gender?: UserGenderType | null;
                age?: number | null;
                x_id?: string | null;
                tiktok_id?: string | null;
                instagram_id?: string | null;
              } | null;
              profileImages?: Array<{
                __typename?: 'Media';
                id?: string | null;
                file_path?: string | null;
                signedUrl?: string | null;
                pivot?: {
                  __typename?: 'UserMediaPivot';
                  display_order?: number | null;
                  verification_status?: ApprovalStatus | null;
                } | null;
              }> | null;
            }
          | null;
        mediaMessage?: {
          __typename?: 'MediaMessage';
          id: string;
          media: {
            __typename?: 'Media';
            id?: string | null;
            file_path?: string | null;
            signedUrl?: string | null;
          };
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      endCursor?: string | null;
    };
  };
};

export type NotificationsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationsQuery = {
  __typename?: 'Query';
  notifications: Array<{
    __typename?: 'Notification';
    id: string;
    type: string;
    notifiable_type: string;
    notifiable_id: number;
    title: string;
    link?: string | null;
    content: string;
    quantity?: number | null;
    additional_data?: any | null;
    read_at?: any | null;
    created_at: any;
    updated_at: any;
  }>;
};

export type PaidLikeProductsQueryVariables = Exact<{ [key: string]: never }>;

export type PaidLikeProductsQuery = {
  __typename?: 'Query';
  paidLikeProducts: Array<{
    __typename?: 'Product';
    id: number;
    name: string;
    description: string;
    price: number;
  }>;
};

export type PaidSubscriptionProductsQueryVariables = Exact<{
  gender?: InputMaybe<UserGenderType>;
}>;

export type PaidSubscriptionProductsQuery = {
  __typename?: 'Query';
  paidSubscriptionProducts: Array<{
    __typename?: 'Product';
    id: number;
    name: string;
    description: string;
    price: number;
  }>;
};

export type ProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'Product';
    id: number;
    name: string;
    description: string;
    price: number;
  };
};

export type SearchUsersQueryVariables = Exact<{
  input: SearchUsersInput;
}>;

export type SearchUsersQuery = {
  __typename?: 'Query';
  searchUsers: {
    __typename?: 'SearchUsersResponse';
    type: string;
    users: {
      __typename?: 'SearchUsersPaginator';
      current_page: number;
      last_page: number;
      total: number;
      has_more_pages: boolean;
      data: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        availableLikesCount?: number | null;
        isLikedByLoginUser?: boolean | null;
        hasLikedLoginUser?: boolean | null;
        recommendStatusForLoginUser?: ApprovalStatus | null;
        created_at?: string | null;
        profile?: {
          __typename?: 'Profile';
          name?: string | null;
          age?: number | null;
          gender?: UserGenderType | null;
          mood?: ProfileMoodType | null;
          genericTextboxDatas?: Array<{
            __typename?: 'ProfileGenericTextboxData';
            id: string;
            key: string;
            name: string;
            pivot?: {
              __typename?: 'UserProfileGenericTextboxDataPivot';
              content: string;
              status: ApprovalStatus;
            } | null;
          }> | null;
        } | null;
        profileImages?: Array<{
          __typename?: 'Media';
          id?: string | null;
          file_path?: string | null;
          signedUrl?: string | null;
          pivot?: {
            __typename?: 'UserMediaPivot';
            display_order?: number | null;
            verification_status?: ApprovalStatus | null;
          } | null;
        }> | null;
        isMatchedWithLoginUser?: {
          __typename?: 'IsMatchedWithLoginUser';
          is_matched?: boolean | null;
          chat_room_id?: number | null;
        } | null;
      }>;
    };
  };
};

export type UserQueryVariables = Exact<{
  input?: InputMaybe<UserQueryInput>;
}>;

export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    is_active?: boolean | null;
    isLikedByLoginUser?: boolean | null;
    hasLikedLoginUser?: boolean | null;
    recommendStatusForLoginUser?: ApprovalStatus | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
    isMatchedWithLoginUser?: {
      __typename?: 'IsMatchedWithLoginUser';
      is_matched?: boolean | null;
      chat_room_id?: number | null;
    } | null;
    profile?: {
      __typename?: 'Profile';
      id?: string | null;
      name?: string | null;
      gender?: UserGenderType | null;
      age?: number | null;
      x_id?: string | null;
      tiktok_id?: string | null;
      instagram_id?: string | null;
      area?: {
        __typename?: 'Area';
        id: string;
        parent_id?: number | null;
        name: string;
      } | null;
      profileDetails?: Array<{
        __typename?: 'ProfileDetail';
        id: string;
        name: string;
        key: string;
        pivot?: {
          __typename?: 'UserProfileDetailPivot';
          value?: string | null;
        } | null;
      }> | null;
      genericTextboxDatas?: Array<{
        __typename?: 'ProfileGenericTextboxData';
        id: string;
        name: string;
        key: string;
        pivot?: {
          __typename?: 'UserProfileGenericTextboxDataPivot';
          content: string;
          status: ApprovalStatus;
        } | null;
      }> | null;
    } | null;
    profileImages?: Array<{
      __typename?: 'Media';
      id?: string | null;
      file_path?: string | null;
      signedUrl?: string | null;
      deleted_at?: string | null;
      pivot?: {
        __typename?: 'UserMediaPivot';
        display_order?: number | null;
        verification_status?: ApprovalStatus | null;
        status?: ApprovalStatus | null;
      } | null;
    }> | null;
    ageVerificationImages?: Array<{
      __typename?: 'Media';
      id?: string | null;
      file_path?: string | null;
      signedUrl?: string | null;
      deleted_at?: string | null;
      pivot?: {
        __typename?: 'UserMediaPivot';
        display_order?: number | null;
        verification_status?: ApprovalStatus | null;
        status?: ApprovalStatus | null;
      } | null;
    }> | null;
    kinks?: Array<{
      __typename?: 'Kink';
      id: string;
      name: string;
      description?: string | null;
      pivot?: { __typename?: 'UserKinkPivot'; value?: string | null } | null;
    }> | null;
    convenientTimes?: Array<{
      __typename?: 'ConvenientTime';
      id: string;
      timeframe: string;
    }> | null;
    areaPreferences?: Array<{
      __typename?: 'Area';
      id: string;
      parent_id?: number | null;
      name: string;
    }> | null;
    attractionFactors?: Array<{
      __typename?: 'AttractionFactor';
      id: string;
      content: string;
      pivot?: {
        __typename?: 'UserAttractionFactorPivot';
        description?: string | null;
      } | null;
    }> | null;
    ageRange?: {
      __typename?: 'AgeRange';
      min_age?: number | null;
      max_age?: number | null;
    } | null;
    femaleProfileHiddenList?: {
      __typename?: 'FemaleProfileHiddenList';
      id: string;
    } | null;
  } | null;
};

export type NewMessageSubscriptionVariables = Exact<{
  input: NewMessageSubscriptionInput;
}>;

export type NewMessageSubscription = {
  __typename?: 'Subscription';
  newMessage?: {
    __typename?: 'Message';
    id: string;
    sender_type?: AccountType | null;
    chat_room_id: string;
    content?: string | null;
    sender?:
      | { __typename?: 'Admin'; id: string }
      | { __typename?: 'User'; id: string }
      | null;
    mediaMessage?: {
      __typename?: 'MediaMessage';
      id: string;
      media: {
        __typename?: 'Media';
        id?: string | null;
        file_path?: string | null;
        signedUrl?: string | null;
      };
    } | null;
  } | null;
};

export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input)
  }
`;
export const ContinueAndCreateUserDocument = gql`
  mutation ContinueAndCreateUser($input: ContinueAndCreateUserInput!) {
    ContinueAndCreateUser(input: $input) {
      id
      email
      password
      created_at
      updated_at
      deleted_at
    }
  }
`;
export const CreateMessageDocument = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      sender {
        ... on User {
          id
        }
        ... on Admin {
          id
        }
      }
      content
      mediaMessage {
        id
        media {
          id
          file_path
          signedUrl
        }
      }
    }
  }
`;
export const CreateRecommendationRejectionReasonDocument = gql`
  mutation CreateRecommendationRejectionReason(
    $input: CreateRecommendationRejectionReasonInput!
  ) {
    createRecommendationRejectionReason(input: $input) {
      status
      message
    }
  }
`;
export const CreateUserDocument = gql`
  mutation CreateUser($input: UserCreateInput!) {
    createUser(input: $input) {
      id
      email
    }
  }
`;
export const DeleteProfileImageDocument = gql`
  mutation DeleteProfileImage($input: DeleteProfileImageInput!) {
    deleteProfileImage(input: $input) {
      id
      deleted_at
    }
  }
`;
export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      id
      email
      created_at
      updated_at
      profile {
        id
        gender
      }
    }
  }
`;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      id
      created_at
      updated_at
      deleted_at
    }
  }
`;
export const MarkMessageAsReadDocument = gql`
  mutation MarkMessageAsRead($input: MarkMessageAsReadInput!) {
    markMessageAsRead(input: $input)
  }
`;
export const MarkNotificationAsReadDocument = gql`
  mutation MarkNotificationAsRead($input: [MarkNotificationAsReadInput!]!) {
    markNotificationAsRead(input: $input)
  }
`;
export const MatchingDocument = gql`
  mutation Matching($input: MatchingInput!) {
    matching(input: $input) {
      status
      message
      match_id
    }
  }
`;
export const OrderItemDocument = gql`
  mutation OrderItem($input: OrderItemInput!) {
    orderItem(input: $input) {
      id
    }
  }
`;
export const OrderSubscriptionDocument = gql`
  mutation OrderSubscription($input: OrderSubscriptionInput!) {
    orderSubscription(input: $input) {
      id
    }
  }
`;
export const RequestRecommendDocument = gql`
  mutation RequestRecommend($input: RequestRecommendInput!) {
    requestRecommend(input: $input) {
      recommend_request {
        id
        user_id
        type
        status
        recommendation {
          id
          male_user_id
          status
          maleUser {
            id
            email
            created_at
          }
        }
      }
      daily_request_counts {
        remaining_count
        manual_count
        ai_count
      }
    }
  }
`;
export const ResendEmailVerificationDocument = gql`
  mutation resendEmailVerification($input: ResendEmailVerificationInput!) {
    resendEmailVerification(input: $input) {
      status
      message
    }
  }
`;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      status
      message
    }
  }
`;
export const SendLikeDocument = gql`
  mutation SendLike($input: SendLikeInput!) {
    sendLike(input: $input) {
      status
      message
      available_likes_count
    }
  }
`;
export const ShowErrorSubscriptionDocument = gql`
  mutation ShowErrorSubscription($input: ShowErrorSubscriptionInput!) {
    showErrorSubscription(input: $input)
  }
`;
export const TerminateUserDocument = gql`
  mutation TerminateUser($input: TerminateUserInput!) {
    terminateUser(input: $input) {
      id
      is_active
      deleted_at
    }
  }
`;
export const TryResetPasswordDocument = gql`
  mutation TryResetPassword($input: TryResetPasswordInput!) {
    tryResetPassword(input: $input) {
      status
      message
    }
  }
`;
export const TryUpdateEmailDocument = gql`
  mutation TryUpdateEmail($input: TryUpdateEmailInput!) {
    tryUpdateEmail(input: $input) {
      status
      message
    }
  }
`;
export const UpdateAcceptanceRangeDocument = gql`
  mutation UpdateAcceptanceRange($input: UpdateAcceptanceRangeInput!) {
    updateAcceptanceRange(input: $input) {
      id
      profile {
        profileDetails {
          id
          name
          pivot {
            value
          }
        }
      }
      ageRange {
        min_age
        max_age
      }
      convenientTimes {
        id
        timeframe
      }
    }
  }
`;
export const UpdateAdditionalProfileDocument = gql`
  mutation UpdateAdditionalProfile($input: UpdateAdditionalProfileInput!) {
    updateAdditionalProfile(input: $input) {
      id
      profile {
        profileDetails {
          id
          name
          pivot {
            value
          }
        }
      }
      attractionFactors {
        id
        content
        pivot {
          description
        }
      }
      kinks {
        id
        name
        pivot {
          value
        }
      }
    }
  }
`;
export const UpdateCreditCardDocument = gql`
  mutation UpdateCreditCard($input: CardInput!) {
    updateCreditCard(input: $input) {
      card_number_last4
      card_number_length
      exp_month
      exp_year
    }
  }
`;
export const UpdateEmailDocument = gql`
  mutation UpdateEmail($input: UpdateEmailInput!) {
    updateEmail(input: $input) {
      status
      message
    }
  }
`;
export const UpdateFemaleProfileHiddenListDocument = gql`
  mutation UpdateFemaleProfileHiddenList(
    $input: UpdateFemaleProfileHiddenListInput!
  ) {
    updateFemaleProfileHiddenList(input: $input) {
      status
      message
    }
  }
`;
export const UpdateMoodDocument = gql`
  mutation UpdateMood($input: UpdateMoodInput!) {
    updateMood(input: $input) {
      id
      profile {
        id
        mood
      }
    }
  }
`;
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      status
      message
    }
  }
`;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      profile {
        name
        age
        area {
          name
        }
        x_id
        tiktok_id
        instagram_id
        profileDetails {
          id
          name
          pivot {
            value
          }
        }
      }
      areaPreferences {
        name
      }
    }
  }
`;
export const UpdateProfileImageDocument = gql`
  mutation UpdateProfileImage($input: ProfileImageInput!) {
    updateProfileImage(input: $input) {
      id
      profileImages {
        id
        file_path
        signedUrl
        pivot {
          display_order
          verification_status
        }
      }
    }
  }
`;
export const UpdateProfileTextDocument = gql`
  mutation UpdateProfileText($input: UpdateProfileTextInput!) {
    updateProfileText(input: $input) {
      id
      profile {
        genericTextboxDatas {
          id
          name
          pivot {
            content
            status
          }
        }
      }
    }
  }
`;
export const UploadMediaDocument = gql`
  mutation UploadMedia($input: UploadMediaInput!) {
    uploadMedia(input: $input) {
      id
      file_path
      deleted_at
      signedUrl
    }
  }
`;
export const ValidateResetPasswordTokenDocument = gql`
  mutation ValidateResetPasswordToken(
    $input: ValidateResetPasswordTokenInput!
  ) {
    validateResetPasswordToken(input: $input) {
      status
      message
    }
  }
`;
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      status
      message
    }
  }
`;
export const AreasDocument = gql`
  query Areas {
    areas {
      id
      name
      children {
        id
        name
        parent_id
      }
    }
  }
`;
export const ChatPageDataDocument = gql`
  query ChatPageData {
    me {
      id
      email
      created_at
      is_active
      profile {
        id
        name
        gender
        age
        x_id
        tiktok_id
        instagram_id
      }
      profileImages {
        id
        file_path
        signedUrl
        pivot {
          display_order
          verification_status
        }
      }
      isActiveFemalePremium
      availableLikesCount
      recommendationsCount
      matchCount
      receivedLikesCount
      adminChatRoomId
      activeCreditCard {
        card_number_last4
        card_number_length
        exp_month
        exp_year
      }
      latestUserSubscription {
        id
        display_joined_at
        display_expired_at
        status
        is_shown_error
        product {
          name
          id
          description
          price
        }
      }
      activeUserSubscription {
        id
        created_at
        end_date
        display_joined_at
        display_expired_at
        product {
          name
          id
          description
          price
        }
      }
    }
    chatRooms {
      id
      users {
        id
        email
        created_at
        is_active
        profile {
          name
        }
        profileImages {
          id
          file_path
          signedUrl
          pivot {
            display_order
            verification_status
          }
        }
      }
      admins {
        id
      }
      latestMessage {
        id
        sender_id
        sender_type
        chat_room_id
        content
        is_read
        created_at
        updated_at
        mediaMessage {
          id
          media {
            id
            file_path
            signedUrl
          }
        }
      }
      unread_messages_count
    }
    matches {
      id
      created_at
      users {
        id
        email
        profile {
          name
        }
        profileImages {
          id
          file_path
          signedUrl
          pivot {
            display_order
            verification_status
          }
        }
      }
    }
  }
`;
export const ChatRoomDocument = gql`
  query ChatRoom($id: ID!) {
    chatRoom(id: $id) {
      id
      users {
        id
        email
        is_active
        created_at
        profile {
          name
        }
        profileImages {
          id
          file_path
          signedUrl
          pivot {
            display_order
            verification_status
          }
        }
      }
      admins {
        id
      }
    }
  }
`;
export const ChatRoomDataDocument = gql`
  query ChatRoomData(
    $roomId: ID!
    $messageLimit: Int!
    $messageCursor: String
  ) {
    chatRoom(id: $roomId) {
      id
      users {
        id
        email
        is_active
        created_at
        profile {
          name
        }
        profileImages {
          id
          file_path
          signedUrl
          pivot {
            display_order
            verification_status
          }
        }
      }
      admins {
        id
      }
    }
    messagesByChatRoomId(
      input: { chat_room_id: $roomId }
      first: $messageLimit
      after: $messageCursor
    ) {
      edges {
        node {
          id
          sender {
            ... on User {
              id
              email
              created_at
              profile {
                id
                name
                gender
                age
                x_id
                tiktok_id
                instagram_id
              }
              profileImages {
                id
                file_path
                signedUrl
                pivot {
                  display_order
                  verification_status
                }
              }
            }
            ... on Admin {
              id
            }
          }
          sender_type
          content
          mediaMessage {
            id
            media {
              id
              file_path
              signedUrl
            }
          }
          created_at
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    me {
      id
      email
      is_active
    }
  }
`;
export const ChatRoomsDocument = gql`
  query ChatRooms {
    chatRooms {
      id
      users {
        id
        email
        created_at
        is_active
        profile {
          name
        }
        profileImages {
          id
          file_path
          signedUrl
          pivot {
            display_order
            verification_status
          }
        }
        created_at
      }
      admins {
        id
      }
      latestMessage {
        id
        sender_id
        sender_type
        chat_room_id
        content
        is_read
        mediaMessage {
          id
          media {
            id
            file_path
            signedUrl
          }
        }
        is_read
        created_at
        updated_at
      }
      unread_messages_count
    }
  }
`;
export const CollaborationsDocument = gql`
  query Collaborations {
    collaborations {
      id
      link
      name
      media {
        id
        file_path
        signedUrl
      }
    }
  }
`;
export const DailyRequestCountsDocument = gql`
  query DailyRequestCounts {
    dailyRequestCounts {
      remaining_count
      manual_count
      ai_count
    }
  }
`;
export const GetAttractionFactorsDocument = gql`
  query GetAttractionFactors {
    getAttractionFactors {
      id
      content
    }
  }
`;
export const GetConvenientTimesDocument = gql`
  query GetConvenientTimes {
    getConvenientTimes {
      id
      timeframe
    }
  }
`;
export const GetFemaleTopDataDocument = gql`
  query GetFemaleTopData(
    $compatibleInput: SearchUsersInput!
    $recentlyJoinedInput: SearchUsersInput!
    $highlyResponsiveInput: SearchUsersInput!
    $nearbyInput: SearchUsersInput!
  ) {
    compatible: searchUsers(input: $compatibleInput) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
    recentlyJoined: searchUsers(input: $recentlyJoinedInput) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
    highlyResponsive: searchUsers(input: $highlyResponsiveInput) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
    nearby: searchUsers(input: $nearbyInput) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
  }
`;
export const GetKinksDocument = gql`
  query GetKinks {
    getKinks {
      id
      name
    }
  }
`;
export const GetMaleTopDataDocument = gql`
  query GetMaleTopData(
    $compatibleInput: SearchUsersInput!
    $recentlyJoinedInput: SearchUsersInput!
    $popularInput: SearchUsersInput!
    $nearbyInput: SearchUsersInput!
  ) {
    compatible: searchUsers(input: $compatibleInput) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
    recentlyJoined: searchUsers(input: $recentlyJoinedInput) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
    popular: searchUsers(input: $popularInput) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
    nearby: searchUsers(input: $nearbyInput) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
  }
`;
export const GetNewsByIdDocument = gql`
  query GetNewsById($id: ID!) {
    getNewsById(id: $id) {
      id
      title
      content
      status
      created_at
      categories {
        id
        name
      }
    }
  }
`;
export const GetNewsListDocument = gql`
  query GetNewsList($first: Int!, $page: Int!) {
    getNewsList(first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        title
        content
        status
        created_at
        categories {
          id
          name
        }
      }
    }
  }
`;
export const GetProfileDetailsDocument = gql`
  query GetProfileDetails {
    getProfileDetails {
      id
      name
      key
    }
  }
`;
export const GetProfileMasterDocument = gql`
  query GetProfileMaster {
    areas {
      id
      name
      children {
        id
        name
        parent_id
      }
    }
    getConvenientTimes {
      id
      timeframe
    }
    getKinks {
      id
      name
    }
    getProfileDetails {
      id
      name
      key
    }
    getTextboxDatas {
      id
      name
      key
    }
  }
`;
export const GetTerminateReasonsDocument = gql`
  query GetTerminateReasons {
    getTerminateReasons {
      id
      reason
    }
  }
`;
export const GetTextboxDatasDocument = gql`
  query GetTextboxDatas {
    getTextboxDatas {
      id
      name
      key
    }
  }
`;
export const LoginCheckMeDocument = gql`
  query LoginCheckMe {
    me {
      is_active
      registration_step
      adminChatRoomId
      profile {
        id
        name
        gender
      }
      ageVerificationImages {
        id
        file_path
        signedUrl
        pivot {
          status
        }
      }
      latestUserSubscription {
        id
        status
        is_shown_error
      }
    }
  }
`;
export const MatchDetailsDocument = gql`
  query MatchDetails($input: MatchDetailsInput!) {
    matchDetails(input: $input) {
      login_user {
        id
        profile {
          name
          gender
        }
        profileImages {
          id
          file_path
          signedUrl
          pivot {
            display_order
            verification_status
          }
        }
      }
      target_user {
        id
        profile {
          name
          gender
        }
        profileImages {
          id
          file_path
          signedUrl
          pivot {
            display_order
            verification_status
          }
        }
      }
      chat_room_id
    }
  }
`;
export const MatchesDocument = gql`
  query Matches {
    matches {
      id
      users {
        id
        email
        created_at
        profile {
          name
        }
        profileImages {
          id
          file_path
          signedUrl
          pivot {
            display_order
            verification_status
          }
        }
      }
      created_at
    }
  }
`;
export const MeDocument = gql`
  query Me {
    me {
      id
      email
      created_at
      is_active
      profile {
        id
        name
        gender
        age
        x_id
        tiktok_id
        instagram_id
        area {
          id
          parent_id
          name
        }
        profileDetails {
          id
          name
          key
          pivot {
            value
          }
        }
        genericTextboxDatas {
          id
          name
          key
          pivot {
            content
            status
          }
        }
      }
      profileImages {
        id
        file_path
        signedUrl
        pivot {
          display_order
          verification_status
        }
      }
      femaleProfileHiddenList {
        id
      }
      kinks {
        id
        name
        description
        pivot {
          value
        }
      }
      convenientTimes {
        id
        timeframe
      }
      areaPreferences {
        id
        parent_id
        name
      }
      attractionFactors {
        id
        content
        pivot {
          description
        }
      }
      ageRange {
        min_age
        max_age
      }
      isActiveFemalePremium
      availableLikesCount
      recommendationsCount
      matchCount
      receivedLikesCount
      adminChatRoomId
      created_at
      activeCreditCard {
        card_number_last4
        card_number_length
        exp_month
        exp_year
      }
      activeUserSubscription {
        id
        display_joined_at
        display_expired_at
        product {
          name
          id
          description
          price
        }
      }
      latestUserSubscription {
        id
        display_joined_at
        display_expired_at
        status
        is_shown_error
        product {
          name
          id
          description
          price
        }
      }
    }
  }
`;
export const MessagesByChatRoomIdDocument = gql`
  query MessagesByChatRoomId(
    $input: MessagesByChatRoomIdInput!
    $limit: Int!
    $cursor: String
  ) {
    messagesByChatRoomId(input: $input, first: $limit, after: $cursor) {
      edges {
        node {
          id
          sender {
            ... on User {
              id
              email
              created_at
              profile {
                id
                name
                gender
                age
                x_id
                tiktok_id
                instagram_id
              }
              profileImages {
                id
                file_path
                signedUrl
                pivot {
                  display_order
                  verification_status
                }
              }
            }
            ... on Admin {
              id
            }
          }
          sender_type
          content
          mediaMessage {
            id
            media {
              id
              file_path
              signedUrl
            }
          }
          created_at
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
export const NotificationsDocument = gql`
  query Notifications {
    notifications {
      id
      type
      notifiable_type
      notifiable_id
      title
      link
      content
      quantity
      additional_data
      read_at
      created_at
      updated_at
    }
  }
`;
export const PaidLikeProductsDocument = gql`
  query PaidLikeProducts {
    paidLikeProducts {
      id
      name
      description
      price
    }
  }
`;
export const PaidSubscriptionProductsDocument = gql`
  query PaidSubscriptionProducts($gender: UserGenderType) {
    paidSubscriptionProducts(gender: $gender) {
      id
      name
      description
      price
    }
  }
`;
export const ProductDocument = gql`
  query Product($id: ID!) {
    product(id: $id) {
      id
      name
      description
      price
    }
  }
`;
export const SearchUsersDocument = gql`
  query SearchUsers($input: SearchUsersInput!) {
    searchUsers(input: $input) {
      type
      users {
        data {
          id
          email
          profile {
            name
            age
            gender
            mood
            genericTextboxDatas {
              id
              key
              name
              pivot {
                content
                status
              }
            }
          }
          profileImages {
            id
            file_path
            signedUrl
            pivot {
              display_order
              verification_status
            }
          }
          availableLikesCount
          isLikedByLoginUser
          hasLikedLoginUser
          isMatchedWithLoginUser {
            is_matched
            chat_room_id
          }
          recommendStatusForLoginUser
          created_at
        }
        current_page
        last_page
        total
        has_more_pages
      }
    }
  }
`;
export const UserDocument = gql`
  query User($input: UserQueryInput) {
    user(input: $input) {
      id
      is_active
      isLikedByLoginUser
      hasLikedLoginUser
      isMatchedWithLoginUser {
        is_matched
        chat_room_id
      }
      recommendStatusForLoginUser
      created_at
      updated_at
      deleted_at
      profile {
        id
        name
        gender
        age
        x_id
        tiktok_id
        instagram_id
        area {
          id
          parent_id
          name
        }
        profileDetails {
          id
          name
          key
          pivot {
            value
          }
        }
        genericTextboxDatas {
          id
          name
          key
          pivot {
            content
            status
          }
        }
      }
      profileImages {
        id
        file_path
        signedUrl
        deleted_at
        pivot {
          display_order
          verification_status
          status
        }
      }
      ageVerificationImages {
        id
        file_path
        signedUrl
        deleted_at
        pivot {
          display_order
          verification_status
          status
        }
      }
      kinks {
        id
        name
        description
        pivot {
          value
        }
      }
      convenientTimes {
        id
        timeframe
      }
      areaPreferences {
        id
        parent_id
        name
      }
      attractionFactors {
        id
        content
        pivot {
          description
        }
      }
      ageRange {
        min_age
        max_age
      }
      femaleProfileHiddenList {
        id
      }
    }
  }
`;
export const NewMessageDocument = gql`
  subscription NewMessage($input: NewMessageSubscriptionInput!) {
    newMessage(input: $input) {
      id
      sender {
        ... on User {
          id
        }
        ... on Admin {
          id
        }
      }
      sender_type
      chat_room_id
      content
      mediaMessage {
        id
        media {
          id
          file_path
          signedUrl
        }
      }
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    CancelSubscription(
      variables: CancelSubscriptionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CancelSubscriptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CancelSubscriptionMutation>(
            CancelSubscriptionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CancelSubscription',
        'mutation',
        variables,
      );
    },
    ContinueAndCreateUser(
      variables: ContinueAndCreateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ContinueAndCreateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ContinueAndCreateUserMutation>(
            ContinueAndCreateUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ContinueAndCreateUser',
        'mutation',
        variables,
      );
    },
    CreateMessage(
      variables: CreateMessageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateMessageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateMessageMutation>(
            CreateMessageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateMessage',
        'mutation',
        variables,
      );
    },
    CreateRecommendationRejectionReason(
      variables: CreateRecommendationRejectionReasonMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateRecommendationRejectionReasonMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateRecommendationRejectionReasonMutation>(
            CreateRecommendationRejectionReasonDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'CreateRecommendationRejectionReason',
        'mutation',
        variables,
      );
    },
    CreateUser(
      variables: CreateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateUserMutation>(CreateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CreateUser',
        'mutation',
        variables,
      );
    },
    DeleteProfileImage(
      variables: DeleteProfileImageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteProfileImageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteProfileImageMutation>(
            DeleteProfileImageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DeleteProfileImage',
        'mutation',
        variables,
      );
    },
    Login(
      variables: LoginMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<LoginMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoginMutation>(LoginDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Login',
        'mutation',
        variables,
      );
    },
    Logout(
      variables?: LogoutMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<LogoutMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LogoutMutation>(LogoutDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Logout',
        'mutation',
        variables,
      );
    },
    MarkMessageAsRead(
      variables: MarkMessageAsReadMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MarkMessageAsReadMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MarkMessageAsReadMutation>(
            MarkMessageAsReadDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'MarkMessageAsRead',
        'mutation',
        variables,
      );
    },
    MarkNotificationAsRead(
      variables: MarkNotificationAsReadMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MarkNotificationAsReadMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MarkNotificationAsReadMutation>(
            MarkNotificationAsReadDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'MarkNotificationAsRead',
        'mutation',
        variables,
      );
    },
    Matching(
      variables: MatchingMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MatchingMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MatchingMutation>(MatchingDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Matching',
        'mutation',
        variables,
      );
    },
    OrderItem(
      variables: OrderItemMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<OrderItemMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderItemMutation>(OrderItemDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'OrderItem',
        'mutation',
        variables,
      );
    },
    OrderSubscription(
      variables: OrderSubscriptionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<OrderSubscriptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<OrderSubscriptionMutation>(
            OrderSubscriptionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'OrderSubscription',
        'mutation',
        variables,
      );
    },
    RequestRecommend(
      variables: RequestRecommendMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RequestRecommendMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RequestRecommendMutation>(
            RequestRecommendDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'RequestRecommend',
        'mutation',
        variables,
      );
    },
    resendEmailVerification(
      variables: ResendEmailVerificationMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ResendEmailVerificationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResendEmailVerificationMutation>(
            ResendEmailVerificationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'resendEmailVerification',
        'mutation',
        variables,
      );
    },
    ResetPassword(
      variables: ResetPasswordMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ResetPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ResetPasswordMutation>(
            ResetPasswordDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ResetPassword',
        'mutation',
        variables,
      );
    },
    SendLike(
      variables: SendLikeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SendLikeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SendLikeMutation>(SendLikeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SendLike',
        'mutation',
        variables,
      );
    },
    ShowErrorSubscription(
      variables: ShowErrorSubscriptionMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ShowErrorSubscriptionMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ShowErrorSubscriptionMutation>(
            ShowErrorSubscriptionDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ShowErrorSubscription',
        'mutation',
        variables,
      );
    },
    TerminateUser(
      variables: TerminateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TerminateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TerminateUserMutation>(
            TerminateUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'TerminateUser',
        'mutation',
        variables,
      );
    },
    TryResetPassword(
      variables: TryResetPasswordMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TryResetPasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TryResetPasswordMutation>(
            TryResetPasswordDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'TryResetPassword',
        'mutation',
        variables,
      );
    },
    TryUpdateEmail(
      variables: TryUpdateEmailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TryUpdateEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TryUpdateEmailMutation>(
            TryUpdateEmailDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'TryUpdateEmail',
        'mutation',
        variables,
      );
    },
    UpdateAcceptanceRange(
      variables: UpdateAcceptanceRangeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAcceptanceRangeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateAcceptanceRangeMutation>(
            UpdateAcceptanceRangeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateAcceptanceRange',
        'mutation',
        variables,
      );
    },
    UpdateAdditionalProfile(
      variables: UpdateAdditionalProfileMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateAdditionalProfileMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateAdditionalProfileMutation>(
            UpdateAdditionalProfileDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateAdditionalProfile',
        'mutation',
        variables,
      );
    },
    UpdateCreditCard(
      variables: UpdateCreditCardMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateCreditCardMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateCreditCardMutation>(
            UpdateCreditCardDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateCreditCard',
        'mutation',
        variables,
      );
    },
    UpdateEmail(
      variables: UpdateEmailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateEmailMutation>(UpdateEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateEmail',
        'mutation',
        variables,
      );
    },
    UpdateFemaleProfileHiddenList(
      variables: UpdateFemaleProfileHiddenListMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateFemaleProfileHiddenListMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateFemaleProfileHiddenListMutation>(
            UpdateFemaleProfileHiddenListDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateFemaleProfileHiddenList',
        'mutation',
        variables,
      );
    },
    UpdateMood(
      variables: UpdateMoodMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateMoodMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateMoodMutation>(UpdateMoodDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UpdateMood',
        'mutation',
        variables,
      );
    },
    UpdatePassword(
      variables: UpdatePasswordMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdatePasswordMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdatePasswordMutation>(
            UpdatePasswordDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdatePassword',
        'mutation',
        variables,
      );
    },
    UpdateProfile(
      variables: UpdateProfileMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateProfileMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProfileMutation>(
            UpdateProfileDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateProfile',
        'mutation',
        variables,
      );
    },
    UpdateProfileImage(
      variables: UpdateProfileImageMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateProfileImageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProfileImageMutation>(
            UpdateProfileImageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateProfileImage',
        'mutation',
        variables,
      );
    },
    UpdateProfileText(
      variables: UpdateProfileTextMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateProfileTextMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateProfileTextMutation>(
            UpdateProfileTextDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'UpdateProfileText',
        'mutation',
        variables,
      );
    },
    UploadMedia(
      variables: UploadMediaMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UploadMediaMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UploadMediaMutation>(UploadMediaDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'UploadMedia',
        'mutation',
        variables,
      );
    },
    ValidateResetPasswordToken(
      variables: ValidateResetPasswordTokenMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ValidateResetPasswordTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ValidateResetPasswordTokenMutation>(
            ValidateResetPasswordTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'ValidateResetPasswordToken',
        'mutation',
        variables,
      );
    },
    VerifyEmail(
      variables: VerifyEmailMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<VerifyEmailMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VerifyEmailMutation>(VerifyEmailDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'VerifyEmail',
        'mutation',
        variables,
      );
    },
    Areas(
      variables?: AreasQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AreasQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AreasQuery>(AreasDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Areas',
        'query',
        variables,
      );
    },
    ChatPageData(
      variables?: ChatPageDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChatPageDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChatPageDataQuery>(ChatPageDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ChatPageData',
        'query',
        variables,
      );
    },
    ChatRoom(
      variables: ChatRoomQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChatRoomQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChatRoomQuery>(ChatRoomDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ChatRoom',
        'query',
        variables,
      );
    },
    ChatRoomData(
      variables: ChatRoomDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChatRoomDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChatRoomDataQuery>(ChatRoomDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ChatRoomData',
        'query',
        variables,
      );
    },
    ChatRooms(
      variables?: ChatRoomsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ChatRoomsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ChatRoomsQuery>(ChatRoomsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ChatRooms',
        'query',
        variables,
      );
    },
    Collaborations(
      variables?: CollaborationsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CollaborationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CollaborationsQuery>(
            CollaborationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'Collaborations',
        'query',
        variables,
      );
    },
    DailyRequestCounts(
      variables?: DailyRequestCountsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DailyRequestCountsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DailyRequestCountsQuery>(
            DailyRequestCountsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'DailyRequestCounts',
        'query',
        variables,
      );
    },
    GetAttractionFactors(
      variables?: GetAttractionFactorsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetAttractionFactorsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetAttractionFactorsQuery>(
            GetAttractionFactorsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetAttractionFactors',
        'query',
        variables,
      );
    },
    GetConvenientTimes(
      variables?: GetConvenientTimesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetConvenientTimesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetConvenientTimesQuery>(
            GetConvenientTimesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetConvenientTimes',
        'query',
        variables,
      );
    },
    GetFemaleTopData(
      variables: GetFemaleTopDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetFemaleTopDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetFemaleTopDataQuery>(
            GetFemaleTopDataDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetFemaleTopData',
        'query',
        variables,
      );
    },
    GetKinks(
      variables?: GetKinksQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetKinksQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetKinksQuery>(GetKinksDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetKinks',
        'query',
        variables,
      );
    },
    GetMaleTopData(
      variables: GetMaleTopDataQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetMaleTopDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetMaleTopDataQuery>(
            GetMaleTopDataDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetMaleTopData',
        'query',
        variables,
      );
    },
    GetNewsById(
      variables: GetNewsByIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetNewsByIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetNewsByIdQuery>(GetNewsByIdDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetNewsById',
        'query',
        variables,
      );
    },
    GetNewsList(
      variables: GetNewsListQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetNewsListQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetNewsListQuery>(GetNewsListDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'GetNewsList',
        'query',
        variables,
      );
    },
    GetProfileDetails(
      variables?: GetProfileDetailsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProfileDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProfileDetailsQuery>(
            GetProfileDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetProfileDetails',
        'query',
        variables,
      );
    },
    GetProfileMaster(
      variables?: GetProfileMasterQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetProfileMasterQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetProfileMasterQuery>(
            GetProfileMasterDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetProfileMaster',
        'query',
        variables,
      );
    },
    GetTerminateReasons(
      variables?: GetTerminateReasonsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTerminateReasonsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTerminateReasonsQuery>(
            GetTerminateReasonsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetTerminateReasons',
        'query',
        variables,
      );
    },
    GetTextboxDatas(
      variables?: GetTextboxDatasQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTextboxDatasQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTextboxDatasQuery>(
            GetTextboxDatasDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'GetTextboxDatas',
        'query',
        variables,
      );
    },
    LoginCheckMe(
      variables?: LoginCheckMeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<LoginCheckMeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LoginCheckMeQuery>(LoginCheckMeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'LoginCheckMe',
        'query',
        variables,
      );
    },
    MatchDetails(
      variables: MatchDetailsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MatchDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MatchDetailsQuery>(MatchDetailsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'MatchDetails',
        'query',
        variables,
      );
    },
    Matches(
      variables?: MatchesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MatchesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MatchesQuery>(MatchesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Matches',
        'query',
        variables,
      );
    },
    Me(
      variables?: MeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MeQuery>(MeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Me',
        'query',
        variables,
      );
    },
    MessagesByChatRoomId(
      variables: MessagesByChatRoomIdQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<MessagesByChatRoomIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MessagesByChatRoomIdQuery>(
            MessagesByChatRoomIdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'MessagesByChatRoomId',
        'query',
        variables,
      );
    },
    Notifications(
      variables?: NotificationsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<NotificationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<NotificationsQuery>(NotificationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Notifications',
        'query',
        variables,
      );
    },
    PaidLikeProducts(
      variables?: PaidLikeProductsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PaidLikeProductsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PaidLikeProductsQuery>(
            PaidLikeProductsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'PaidLikeProducts',
        'query',
        variables,
      );
    },
    PaidSubscriptionProducts(
      variables?: PaidSubscriptionProductsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<PaidSubscriptionProductsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PaidSubscriptionProductsQuery>(
            PaidSubscriptionProductsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'PaidSubscriptionProducts',
        'query',
        variables,
      );
    },
    Product(
      variables: ProductQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ProductQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProductQuery>(ProductDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Product',
        'query',
        variables,
      );
    },
    SearchUsers(
      variables: SearchUsersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SearchUsersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchUsersQuery>(SearchUsersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SearchUsers',
        'query',
        variables,
      );
    },
    User(
      variables?: UserQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserQuery>(UserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'User',
        'query',
        variables,
      );
    },
    NewMessage(
      variables: NewMessageSubscriptionVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<NewMessageSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<NewMessageSubscription>(
            NewMessageDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'NewMessage',
        'subscription',
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
